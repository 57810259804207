import { Dispatch, SetStateAction } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { recipientDetailsInLocalStorage } from '../../helpers/recipientData'
import {
  KWLRecipient,
  DocumentType,
  BeneficiaryLookUpForm,
  BeneficiaryType,
  CompanyRecipient,
  ValidateWithdrawalAccountNumberRequestData,
  MobileWalletRecipient,
  CreateBeneficiaryByDefaultCompanyRequestBody,
  UpdateCompanyBeneficiaryRequestBody,
} from '../../interfaces/beneficiary'
import {
  ClearBeneficiaries,
  ClearCreateMobileRecipientError,
  ClearRecipientSuccess,
  ClearWithdrawalAccountInfo,
  CreateBeneficiaryByDefaultCompany,
  CreateMobileWalletRecipient,
  CreateRecipient,
  DeleteRecipient,
  GetAmlAccessTokenForCompanyBeneficiary,
  GetBeneficiaries,
  GetBeneficiariesBySearch,
  GetBeneficiaryCountryList,
  GetBeneficiaryFieldsConfig,
  GetCompanyListForBeneficiary,
  GetCountryAllList,
  GetProviderListForWithdrawal,
  GetRecipient,
  LookUpBeneficiary,
  SetBeneficiary,
  UpdateCompanyBeneficiary,
  UpdateRecipient,
  ValidateWithdrawalAccountNumber,
} from '../../interfaces/redux-types/beneficiary'
import { AmlStatus } from '../../interfaces/user'
import { beneficiaryService } from '../../services/beneficiaryService'
import { beneficiaryConstants } from '../constants/beneficiaryConstants'
import { errorConstants } from '../constants/errorConstants'

function getBeneficiaryCountryList() {
  return async (dispatch: Dispatch<GetBeneficiaryCountryList>) => {
    dispatch({ type: beneficiaryConstants.GET_BENEFICIARY_COUNTRY_LIST_REQUEST })
    beneficiaryService.getBeneficiaryCountryList().then(
      res => {
        dispatch({
          type: beneficiaryConstants.GET_BENEFICIARY_COUNTRY_LIST_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({
          type: beneficiaryConstants.GET_BENEFICIARY_COUNTRY_LIST_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function getCountryAllList() {
  return async (dispatch: Dispatch<GetCountryAllList>) => {
    dispatch({ type: beneficiaryConstants.GET_COUNTRY_ALL_LIST_REQUEST })
    beneficiaryService.getCountryAllList().then(
      res => {
        dispatch({
          type: beneficiaryConstants.GET_COUNTRY_ALL_LIST_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({
          type: beneficiaryConstants.GET_COUNTRY_ALL_LIST_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function createRecipient(
  recipient: KWLRecipient | CompanyRecipient,
  navigate: NavigateFunction,
  from: string,
  amlStatus: AmlStatus | undefined
) {
  return async (dispatch: Dispatch<CreateRecipient>) => {
    dispatch({ type: beneficiaryConstants.CREATE_RECIPIENT_REQUEST })
    await beneficiaryService.createRecipient(recipient).then(
      res => {
        dispatch({
          type: beneficiaryConstants.CREATE_RECIPIENT_SUCCESS,
        })
        recipientDetailsInLocalStorage.saveRecipientDetails(res)

        if (recipient.type === BeneficiaryType.Company) {
          return navigate(`/beneficiary/${res.id}`, { state: { shouldItMoveToAmlCheck: true } })
        }

        if (from === 'continue-transaction') {
          if (amlStatus === AmlStatus.Verified) {
            navigate('/transaction/new')
          } else {
            navigate('/beneficiaries/select-beneficiary')
          }
        } else {
          navigate(from)
        }
      },
      err => {
        if (err.errorCode === '4000000241') {
          dispatch({ type: beneficiaryConstants.CREATE_RECIPIENT_FAILURE, payload: 'Please, enter a valid account number' })
          return
        }
        if (
          err.errorCode === '4000000253' ||
          err.errorCode === '5000000194' ||
          err.errorCode === '5000000198' ||
          err.errorCode === '5000000195'
        ) {
          dispatch({ type: beneficiaryConstants.CREATE_RECIPIENT_FAILURE })
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: err.errorCode } })
          return
        }

        dispatch({ type: beneficiaryConstants.CREATE_RECIPIENT_FAILURE })
      }
    )
  }
}

function createBeneficiaryByDefaultCompany(
  companyId: string,
  body: CreateBeneficiaryByDefaultCompanyRequestBody,
  navigate: NavigateFunction,
  from: string,
  amlStatus: AmlStatus | undefined
) {
  return async (dispatch: Dispatch<CreateBeneficiaryByDefaultCompany>) => {
    dispatch({ type: beneficiaryConstants.CREATE_BENEFICIARY_BY_DEFAULT_COMPANY_REQUEST })
    await beneficiaryService.createBeneficiaryByDefaultCompany(companyId, body).then(
      res => {
        dispatch({
          type: beneficiaryConstants.CREATE_BENEFICIARY_BY_DEFAULT_COMPANY_SUCCESS,
        })
        recipientDetailsInLocalStorage.saveRecipientDetails(res)

        if (from === 'continue-transaction') {
          if (amlStatus === AmlStatus.Verified) {
            navigate('/transaction/new')
          } else {
            navigate('/beneficiaries/select-beneficiary')
          }
        } else {
          navigate(from)
        }
      },
      err => {
        if (err.errorCode) {
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: err.errorCode } })
        } else {
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '' } })
        }

        dispatch({ type: beneficiaryConstants.CREATE_BENEFICIARY_BY_DEFAULT_COMPANY_FAILURE })
      }
    )
  }
}

function createMobileWalletRecipient(
  recipient: MobileWalletRecipient,
  makeRedirect?: () => void,
) {
  return async (dispatch: Dispatch<CreateMobileWalletRecipient>) => {
    dispatch({ type: beneficiaryConstants.CREATE_MOBILE_WALLET_RECIPIENT_REQUEST })
    await beneficiaryService.createMobileWalletRecipient(recipient).then(
      res => {
        dispatch({
          type: beneficiaryConstants.CREATE_MOBILE_WALLET_RECIPIENT_SUCCESS,
        })
        recipientDetailsInLocalStorage.saveRecipientDetails(res)

        makeRedirect && makeRedirect()

      },
      err => {
        if (err.errorCode === '40400000109') {
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: err.errorCode } })
        }

        dispatch({ type: beneficiaryConstants.CREATE_MOBILE_WALLET_RECIPIENT_FAILURE })
      }
    )
  }
}

function clearCreateMobileWalletRecipientError() {
  return (dispatch: Dispatch<ClearCreateMobileRecipientError>) => {
    dispatch({ type: beneficiaryConstants.CLEAR_CREATE_MOBILE_WALLET_RECIPIENT_ERROR })
  }
}

function lookUpBeneficiary(
  lookUpData: BeneficiaryLookUpForm,
  setIsLookUpPage: Dispatch<SetStateAction<boolean>>
) {
  return (dispatch: Dispatch<LookUpBeneficiary>) => {
    dispatch({ type: beneficiaryConstants.LOOK_UP_BENEFICIARY_REQUEST })
    beneficiaryService.lookUpBeneficiary(lookUpData.nationalId).then(
      res => {
        dispatch({
          type: beneficiaryConstants.LOOK_UP_BENEFICIARY_SUCCESS,
          payload: {
            ...res,
            type: Number(lookUpData.type),
            documentType: DocumentType.NationalId,
            documentNumber: lookUpData.nationalId,
            documentCountryIso: 'ZW',
            countryId: 240,
          },
        })
        setIsLookUpPage(false)
      },
      err => {
        dispatch({
          type: beneficiaryConstants.LOOK_UP_BENEFICIARY_FAILURE,
        })
        setIsLookUpPage(false)
      }
    )
  }
}

function getRecipient(id: string, navigate: NavigateFunction) {
  return async (dispatch: Dispatch<GetRecipient>) => {
    dispatch({ type: beneficiaryConstants.GET_RECIPIENT_REQUEST })
    await beneficiaryService.getRecipient(id).then(
      res => {
        dispatch({ type: beneficiaryConstants.GET_RECIPIENT_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: beneficiaryConstants.GET_RECIPIENT_FAILURE, payload: err.message })
        if (err.status === 400 || err.code === 404) {
          navigate && navigate('/beneficiaries')
        }
      }
    )
  }
}

function getBeneficiaryFieldsConfig() {
  return async (dispatch: Dispatch<GetBeneficiaryFieldsConfig>) => {
    dispatch({ type: beneficiaryConstants.GET_BENEFICIARY_FIELDS_CONFIG_REQUEST })
    await beneficiaryService.getBeneficiaryFieldsConfig().then(
      res => {
        dispatch({ type: beneficiaryConstants.GET_BENEFICIARY_FIELDS_CONFIG_SUCCESS, payload: res })
      },
      err => {
        dispatch({
          type: beneficiaryConstants.GET_BENEFICIARY_FIELDS_CONFIG_FAILURE,
          payload: err.message,
        })
      }
    )
  }
}

function updateRecipient(
  recipientId: string,
  recipient: KWLRecipient | CompanyRecipient,
  navigate: NavigateFunction,
  from: string,
  searchedValue: string | undefined
) {
  return async (dispatch: Dispatch<UpdateRecipient>) => {
    dispatch({ type: beneficiaryConstants.UPDATE_RECIPIENT_REQUEST })
    await beneficiaryService.updateRecipient(recipientId, recipient).then(
      () => {
        dispatch({ type: beneficiaryConstants.UPDATE_RECIPIENT_SUCCESS })
        navigate(`${from}`, { state: { searchedValue } })
      },
      err => {
        if (err.errorCode === '4000000241') {
          dispatch({
            type: beneficiaryConstants.UPDATE_RECIPIENT_FAILURE,
            payload: 'Please, enter a valid account number'
          })
          return
        }
        if (
          err.errorCode === '4000000253' ||
          err.errorCode === '5000000194' ||
          err.errorCode === '5000000198' ||
          err.errorCode === '5000000195'
        ) {
          dispatch({ type: beneficiaryConstants.UPDATE_RECIPIENT_FAILURE })
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: err.errorCode } })
          return
        }

        dispatch({ type: beneficiaryConstants.UPDATE_RECIPIENT_FAILURE })
      }
    )
  }
}

function updateCompanyBeneficiary(
  beneficiaryId: string,
  body: UpdateCompanyBeneficiaryRequestBody,
  navigate: NavigateFunction,
  from: string,
  searchedValue: string | undefined
) {
  return async (dispatch: Dispatch<UpdateCompanyBeneficiary>) => {
    dispatch({ type: beneficiaryConstants.UPDATE_COMPANY_BENEFICIARY_REQUEST })

    await beneficiaryService.updateCompanyBeneficiary(beneficiaryId, body).then(
      () => {
        dispatch({ type: beneficiaryConstants.UPDATE_COMPANY_BENEFICIARY_SUCCESS })
        navigate(`${from}`, { state: { searchedValue } })
      },
      err => {
        if (err.errorCode) {
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: err.errorCode } })
        } else {
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '' } })
        }

        dispatch({ type: beneficiaryConstants.UPDATE_COMPANY_BENEFICIARY_FAILURE })
      }
    )
  }
}

function deleteRecipient(recipientId: number) {
  return async (dispatch: Dispatch<DeleteRecipient>) => {
    dispatch({ type: beneficiaryConstants.DELETE_RECIPIENT_REQUEST })
    await beneficiaryService.deleteRecipient(recipientId).then(
      () => {
        dispatch({ type: beneficiaryConstants.DELETE_RECIPIENT_SUCCESS, payload: recipientId })
      },
      err => {
        dispatch({ type: beneficiaryConstants.DELETE_RECIPIENT_FAILURE, payload: err })
      }
    )
  }
}

function clearRecipient() {
  return (dispatch: Dispatch<ClearRecipientSuccess>) => {
    dispatch({ type: beneficiaryConstants.CLEAR_RECIPIENT_SUCCESS })
  }
}

function getBeneficiaries(offset: number, elementAmount: number) {
  return async (dispatch: Dispatch<GetBeneficiaries>) => {
    dispatch({ type: beneficiaryConstants.GET_BENEFICIARIES_REQUEST, offset })
    await beneficiaryService.getBeneficiaries(offset, elementAmount).then(
      res => {
        dispatch({ type: beneficiaryConstants.GET_BENEFICIARIES_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: beneficiaryConstants.GET_BENEFICIARIES_FAILURE, payload: err })
      }
    )
  }
}

function getBeneficiariesBySearch(offset: number, elementAmount: number, searchedData: string) {
  return async (dispatch: Dispatch<GetBeneficiariesBySearch>) => {
    dispatch({
      type: beneficiaryConstants.GET_BENEFICIARIES_BY_SEARCH_REQUEST,
      offset,
    })
    await beneficiaryService.getBeneficiaries(offset, elementAmount, searchedData).then(
      res => {
        dispatch({
          type: beneficiaryConstants.GET_BENEFICIARIES_BY_SEARCH_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({ type: beneficiaryConstants.GET_BENEFICIARIES_BY_SEARCH_FAILURE })
      }
    )
  }
}

function clearBeneficiaries() {
  return async (dispatch: Dispatch<ClearBeneficiaries>) => {
    dispatch({ type: beneficiaryConstants.CLEAR_BENEFICIARIES })
  }
}

function setBeneficiaryType(
  type: BeneficiaryType,
  setIsLookUpPage: Dispatch<SetStateAction<boolean>>
) {
  return async (dispatch: Dispatch<SetBeneficiary>) => {
    dispatch({ type: beneficiaryConstants.SET_BENEFICIARY_TYPE, payload: type })
    setIsLookUpPage(false)
  }
}

function getProviderListForWithdrawal() {
  return async (dispatch: Dispatch<GetProviderListForWithdrawal>) => {
    dispatch({
      type: beneficiaryConstants.GET_PROVIDER_LIST_FOR_WITHDRAWAL_REQUEST,
    })

    await beneficiaryService.getProviderListForWithdrawal().then(
      res => {
        dispatch({
          type: beneficiaryConstants.GET_PROVIDER_LIST_FOR_WITHDRAWAL_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({ type: beneficiaryConstants.GET_PROVIDER_LIST_FOR_WITHDRAWAL_FAILURE })
      }
    )
  }
}

function validateWithdrawalAccountNumber(requestData: ValidateWithdrawalAccountNumberRequestData) {
  return (dispatch: Dispatch<ValidateWithdrawalAccountNumber>) => {
    dispatch({
      type: beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_REQUEST,
      payload: requestData
    })

    beneficiaryService.validateWithdrawalAccountNumber(requestData).then(
      res => {
        if (!res.isValid) {
          dispatch({
            type: beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_FAILURE,
            payload: "Please, enter a valid account number"
          })

          return
        }

        if (res.currency !== 'USD') {
          dispatch({
            type: beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_FAILURE,
            payload: 'Bank account currency is not supported'
          })

          return
        }

        dispatch({
          type: beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({
          type: beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_FAILURE,
          payload: "Please, enter a valid account number"
        })
      }
    )
  }
}

function clearWithdrawalAccountInfo() {
  return async (dispatch: Dispatch<ClearWithdrawalAccountInfo>) => {
    dispatch({ type: beneficiaryConstants.CLEAR_WITHDRAWAL_ACCOUNT_INFO })
  }
}

function getCompanyListForBeneficiary() {
  return (dispatch: Dispatch<GetCompanyListForBeneficiary>) => {
    dispatch({
      type: beneficiaryConstants.GET_COMPANY_LIST_FOR_BENEFICIARY_REQUEST,
    })

    beneficiaryService.getCompanyListForBeneficiary().then(
      res => {
        dispatch({
          type: beneficiaryConstants.GET_COMPANY_LIST_FOR_BENEFICIARY_SUCCESS,
          payload: res
        })


      },

      err => {
        dispatch({
          type: beneficiaryConstants.GET_COMPANY_LIST_FOR_BENEFICIARY_FAILURE,
        })
      }
    )
  }
}

function getAmlAccessTokenForCompanyBeneficiary(beneficiaryId: string) {
  return (dispatch: Dispatch<GetAmlAccessTokenForCompanyBeneficiary>) => {
    dispatch({
      type: beneficiaryConstants.GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_REQUEST,
    })

    beneficiaryService.getAmlAccessTokenForCompanyBeneficiary(beneficiaryId).then(
      res => {
        dispatch({
          type: beneficiaryConstants.GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_SUCCESS,
          payload: res
        })


      },

      err => {
        dispatch({
          type: beneficiaryConstants.GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_FAILURE,
        })
      }
    )
  }
}

export const beneficiaryActions = {
  getBeneficiaryCountryList,
  getCountryAllList,
  createRecipient,
  createMobileWalletRecipient,
  createBeneficiaryByDefaultCompany,
  lookUpBeneficiary,
  getRecipient,
  updateRecipient,
  updateCompanyBeneficiary,
  deleteRecipient,
  clearRecipient,
  getBeneficiaries,
  getBeneficiariesBySearch,
  clearBeneficiaries,
  getBeneficiaryFieldsConfig,
  setBeneficiaryType,
  getProviderListForWithdrawal,
  validateWithdrawalAccountNumber,
  clearWithdrawalAccountInfo,
  clearCreateMobileWalletRecipientError,
  getCompanyListForBeneficiary,
  getAmlAccessTokenForCompanyBeneficiary,
}
