import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CompanyBeneficiaryFormType,
  CompanyRecipient,
  GetBeneficiaryFieldsConfigResponse,
  KWLRecipient,
} from '../../interfaces/beneficiary'
import { convertFormDataDependingOnBeneficiaryConfig, trimObjectFields } from '../../helpers/utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { beneficiaryYupSchemes } from '../../helpers/yupSchemes/beneficiaryYupSchemes'
import { Icon } from '../icons'
import FormInput from '../interfaceComponents/FormInput'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import SelectForm from '../interfaceComponents/SelectForm'
import { Option } from '../../interfaces/commonTypes'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import { AmlStatus } from '../../interfaces/user'

type CompanyBeneficiaryFormProps = {
  recipient: CompanyRecipient
  canFormChange: boolean
  toggleEditingForm: () => void
  sendRequest: (recipient: (KWLRecipient | CompanyBeneficiaryFormType) & { companyVariant?: string }) => void
  onCancel: () => void
  textButton: string
  isShownEditBtn: boolean
  beneficiaryFieldsConfig: GetBeneficiaryFieldsConfigResponse
  providerListForWithdrawalOptions?: Array<Option>
  countryOptionsForCompany: Array<Option>
  type: 'recipient' | 'beneficiary'
  isNewBeneficiary: boolean
  onAmlCheckingBtn?: () => void
}

const CompanyBeneficiaryForm = ({
  sendRequest,
  canFormChange,
  toggleEditingForm,
  textButton,
  onCancel,
  isShownEditBtn,
  recipient,
  beneficiaryFieldsConfig,
  providerListForWithdrawalOptions,
  countryOptionsForCompany,
  type,
  isNewBeneficiary,
  onAmlCheckingBtn,
}: CompanyBeneficiaryFormProps) => {
  const dispatch = useAppDispatch()

  const {
    beneficiaryCreationLoadState,
    beneficiaryUpdateLoadState,
    validateWithdrawalAccountNumberLoadState,
    withdrawalAccountNumber,
    withdrawalBankId,
    withdrawalAccountNumberInfo,
    error: errorBeneficiary,
  } = useAppSelector(state => state.beneficiary)

  const errorTx = useAppSelector(state => state.remittance.error)
  const updateRecipientInTopUpLoadState = useAppSelector(
    state => state.remittance.updateRecipientInTopUpLoadState
  )
  const companyListForBeneficiary = useAppSelector(
    state => state.beneficiary.companyListForBeneficiary
  )

  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const isDisabledForRecipient = useMemo(() => {
    if (type === 'recipient') {
      return true
    }

    return !canFormChange
  }, [canFormChange, type])

  const companyNameOption = useMemo(() => {
    if (isNewBeneficiary) {
      const manualOption = {
        label: 'Manually',
        value: 'Manually',
      }

      const companyListOptions = companyListForBeneficiary.map(company => ({
        label: company.companyName,
        value: company.id || '',
      }))

      return [...companyListOptions, manualOption]
    } else {
      return [
        {
          label: recipient.companyName,
          value: recipient.companyName,
        },
      ]
    }
  }, [companyListForBeneficiary, isNewBeneficiary, recipient.companyName])

  const isLoadingDisabledBtn = useMemo(() => {
    return (
      beneficiaryCreationLoadState.isLoading ||
      beneficiaryUpdateLoadState.isLoading ||
      updateRecipientInTopUpLoadState.isLoading ||
      validateWithdrawalAccountNumberLoadState.isLoading
    )
  }, [
    beneficiaryCreationLoadState.isLoading,
    beneficiaryUpdateLoadState.isLoading,
    updateRecipientInTopUpLoadState.isLoading,
    validateWithdrawalAccountNumberLoadState.isLoading,
  ])

  const isVisibleWithdrawalFields = useMemo(
    () =>
      beneficiaryFieldsConfig?.Company.AccountNumber.visible &&
      beneficiaryFieldsConfig?.Company.BankName.visible &&
      beneficiaryFieldsConfig?.Company.NotificationEmail.visible &&
      beneficiaryFieldsConfig?.Company.Reference.visible,
    [
      beneficiaryFieldsConfig?.Company.AccountNumber.visible,
      beneficiaryFieldsConfig?.Company.BankName.visible,
      beneficiaryFieldsConfig?.Company.NotificationEmail.visible,
      beneficiaryFieldsConfig?.Company.Reference.visible,
    ]
  )

  useEffect(() => {
    dispatch(beneficiaryActions.getCompanyListForBeneficiary())

    return () => {
      dispatch(beneficiaryActions.clearWithdrawalAccountInfo())
    }
  }, [dispatch])

  const { register, handleSubmit, formState, setValue, setError, clearErrors, watch } =
    useForm<CompanyBeneficiaryFormType>({
      resolver: yupResolver(
        beneficiaryYupSchemes.companyBeneficiaryScheme(beneficiaryFieldsConfig)
      ),
    })

  const watchedBankId = watch('bankId')
  const watchedAccountNumber = watch('accountNumber')
  const watchedReference = watch('reference')
  const watchedNotificationEmail = watch('notificationEmail')
  const watchedCompanyVariant = watch('companyVariant')

  const isShownBankDetailsDependingOnCompanyVariant = useMemo(() => {
    return Boolean(watchedCompanyVariant)
  }, [watchedCompanyVariant])

  const isCertainProviderName = useMemo(() => {
    if (!providerListForWithdrawalOptions) {
      return false
    }

    const currentWithdrawalProviderName = providerListForWithdrawalOptions.filter(
      providerItem => String(providerItem.value) === String(watchedBankId)
    )

    return currentWithdrawalProviderName[0]?.label === 'CBZ'
  }, [providerListForWithdrawalOptions, watchedBankId])

  const isValidAccountNumber = useMemo(() => {
    if (watchedCompanyVariant && watchedCompanyVariant !== 'Manually') {
      return true
    }
    if (!isCertainProviderName || !isVisibleWithdrawalFields) {
      return true
    }

    const currentAccountNumber = watchedAccountNumber ? String(watchedAccountNumber).trim() : ''

    return (
      currentAccountNumber === withdrawalAccountNumber &&
      String(watchedBankId) === String(withdrawalBankId) &&
      !errorBeneficiary &&
      withdrawalAccountNumberInfo
    )
  }, [
    errorBeneficiary,
    isCertainProviderName,
    isVisibleWithdrawalFields,
    watchedAccountNumber,
    watchedBankId,
    watchedCompanyVariant,
    withdrawalAccountNumber,
    withdrawalAccountNumberInfo,
    withdrawalBankId,
  ])

  const isShownWithdrawalInfo = useMemo(() => {
    if (!isCertainProviderName || !isVisibleWithdrawalFields) {
      return false
    }

    const currentAccountNumber = watchedAccountNumber ? String(watchedAccountNumber).trim() : ''

    return (
      currentAccountNumber === withdrawalAccountNumber &&
      String(watchedBankId) === String(withdrawalBankId) &&
      !errorBeneficiary &&
      withdrawalAccountNumberInfo
    )
  }, [
    errorBeneficiary,
    isCertainProviderName,
    isVisibleWithdrawalFields,
    watchedAccountNumber,
    watchedBankId,
    withdrawalAccountNumber,
    withdrawalAccountNumberInfo,
    withdrawalBankId,
  ])

  const updateCashOutAccountSectionErrors = useCallback(() => {
    setValue('accountNumber', watchedAccountNumber, { shouldValidate: true })
    setValue('reference', watchedReference, { shouldValidate: true })
    setValue('notificationEmail', watchedNotificationEmail, { shouldValidate: true })
  }, [setValue, watchedNotificationEmail, watchedReference, watchedAccountNumber])

  useEffect(() => {
    if (watchedBankId && watchedBankId !== '-' && formState.isSubmitted) {
      updateCashOutAccountSectionErrors()
    }

    if (watchedBankId === '-' && formState.isSubmitted) {
      updateCashOutAccountSectionErrors()
    }
  }, [formState.isSubmitted, updateCashOutAccountSectionErrors, watchedBankId])

  useEffect(() => {
    if (errorBeneficiary) {
      return setError('accountNumber', { message: errorBeneficiary })
    } else {
      return clearErrors('accountNumber')
    }
  }, [clearErrors, errorBeneficiary, setError])

  useEffect(() => {
    if (errorTx && typeof errorTx === 'string') {
      return setError('accountNumber', { message: errorTx })
    } else {
      return clearErrors('accountNumber')
    }
  }, [clearErrors, errorTx, setError])

  const changeFieldByVariantName = useCallback(() => {
    const currentCompany = companyListForBeneficiary.find(
      company => String(company?.id) === watchedCompanyVariant
    )

    if (!isNewBeneficiary) {
      return
    }

    if (currentCompany) {
      setValue('companyName', currentCompany?.companyName || '')
      setValue('accountNumber', currentCompany?.accountNumber || '')
      setValue('notificationEmail', currentCompany?.notificationEmail || '')
    } else {
      setValue('companyName', '')
      setValue('accountNumber', '')
      setValue('notificationEmail', '')
    }
  }, [companyListForBeneficiary, isNewBeneficiary, setValue, watchedCompanyVariant])

  useEffect(() => {
    changeFieldByVariantName()
  }, [changeFieldByVariantName])

  const setFormValue = useCallback(
    (recipient: CompanyRecipient) => {
      setValue('bankId', recipient.bankId || 1)
      setValue('countryId', 240)

      if (isNewBeneficiary) {
        setValue('reference', '')
        setValue('companyVariant', '')
      } else {
        setValue('companyVariant', recipient.companyName || '')
        setValue('companyName', recipient.companyName || '')
        setValue('accountNumber', recipient.accountNumber || '')
        setValue('notificationEmail', recipient.notificationEmail || '')
        setValue('reference', recipient.reference || '')
      }
    },
    [isNewBeneficiary, setValue]
  )

  useEffect(() => {
    if (isVisibleWithdrawalFields && providerListForWithdrawalOptions && recipient) {
      setFormValue(recipient)
      return
    }
    if (recipient) {
      setFormValue(recipient)
      return
    }
  }, [isVisibleWithdrawalFields, providerListForWithdrawalOptions, recipient, setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onVerify = (data: CompanyBeneficiaryFormType) => {
    const omittedData = omit(data, ['companyVariant'])
    const modifiedRecipientDetails = convertFormDataDependingOnBeneficiaryConfig(
      omittedData,
      beneficiaryFieldsConfig
    ) as CompanyBeneficiaryFormType
    const recipient = trimObjectFields<CompanyBeneficiaryFormType>(modifiedRecipientDetails)

    if (!isValidAccountNumber && canFormChange) {
      if (data.accountNumber && data.bankId) {
        const requestData = {
          accountNumber: data.accountNumber,
          bankId: data.bankId,
        }

        dispatch(beneficiaryActions.validateWithdrawalAccountNumber(requestData))
        return
      }
    }

    sendRequest({ ...recipient, ...{ companyVariant: data.companyVariant } })
  }

  const textConfirmButton = useMemo(() => {
    if (watchedCompanyVariant && watchedCompanyVariant !== 'Manually') {
      return textButton
    }
    if (isValidAccountNumber || !canFormChange) {
      return textButton
    }

    return 'Validate'
  }, [canFormChange, isValidAccountNumber, textButton, watchedCompanyVariant])

  const isShownAmlCheckingBtn = useMemo(
    () =>
      type === 'beneficiary' &&
      !isNewBeneficiary &&
      (recipient.amlInspectionStatus === AmlStatus.Initiated ||
        recipient.amlInspectionStatus === AmlStatus.Pending ||
        recipient.amlInspectionStatus === AmlStatus.RejectedWithRetry),
    [isNewBeneficiary, recipient.amlInspectionStatus, type]
  )

  return (
    <>
      {recipient.amlInspectionStatus && !isNewBeneficiary && (
        <h2>Aml Checking Status: {AmlStatus[recipient.amlInspectionStatus]}</h2>
      )}
      {isShownAmlCheckingBtn && (
        <div className="btn-wrapper justify-end">
          <button
            type="button"
            className="btn btn-next"
            onClick={onAmlCheckingBtn}
          >
            Pass Aml Checking
          </button>
        </div>
      )}
      <form id="beneficiary-details-form" onSubmit={handleSubmit(onVerify)}>
        <div className="details-section">
          <div className="details-section__title">
            <h2>Company Details</h2>
            {!canFormChange && isShownEditBtn && (
              <button className="editButtons-text" onClick={toggleEditingForm} type="button">
                <Icon.EditIcon />
                <span className="fz-small">Edit...</span>
              </button>
            )}
          </div>
          {beneficiaryFieldsConfig?.Company.CompanyName.visible && (
            <>
              <SelectForm<CompanyBeneficiaryFormType>
                id="companyVariant"
                register={register}
                error={formState.errors.companyVariant?.message}
                options={companyNameOption}
                className="select-form"
                defaultOption="Select Company"
                disabled={!isNewBeneficiary}
                watch={watch}
              />
              {watchedCompanyVariant === 'Manually' && isNewBeneficiary && (
                <FormInput<CompanyBeneficiaryFormType>
                  id="companyName"
                  register={register}
                  error={formState.errors.companyName?.message}
                  className="formInput"
                  type="string"
                  maxLength={64}
                  label="Company Name"
                  disabled={!isNewBeneficiary}
                />
              )}
            </>
          )}
          <SelectForm<CompanyBeneficiaryFormType>
            id="countryId"
            register={register}
            options={countryOptionsForCompany}
            className="select-form"
            defaultOption="Select Country"
            disabled={!isNewBeneficiary}
            watch={watch}
          />
          {isVisibleWithdrawalFields && isShownBankDetailsDependingOnCompanyVariant && (
            <>
              <h2>Foreign Currency Account/ Mobile Wallet Details</h2>

              <SelectForm<CompanyBeneficiaryFormType>
                id="bankId"
                register={register}
                options={providerListForWithdrawalOptions || []}
                className="select-form"
                defaultOption="Select a Bank"
                // disabled={!canFormChange}
                disabled
                watch={watch}
                error={formState.errors.bankId?.message}
              />
              <FormInput<CompanyBeneficiaryFormType>
                id="accountNumber"
                register={register}
                error={formState.errors.accountNumber?.message}
                className="formInput"
                type="string"
                maxLength={32}
                label="USD Bank or Wallet account No or Merchant Code"
                disabled={watchedCompanyVariant !== 'Manually' || !isNewBeneficiary}
              />
              {isShownWithdrawalInfo && (
                <div className="withdrawal-account-number-info">
                  <div className="withdrawal-account-number-info-raw">
                    <div>Account Name:</div>
                    <div>{withdrawalAccountNumberInfo?.holderName}</div>
                  </div>
                  <div className="withdrawal-account-number-info-raw">
                    <div>Currency:</div>
                    <div>{withdrawalAccountNumberInfo?.currency}</div>
                  </div>
                </div>
              )}
              <FormInput<CompanyBeneficiaryFormType>
                id="reference"
                register={register}
                error={formState.errors.reference?.message}
                className="formInput"
                type="string"
                maxLength={64}
                label="Beneficiary Reference"
                disabled={isDisabledForRecipient}
                additionalLabel="*Fill the field like: Stand no & Buyer Name & Surname"
              />
              <FormInput<CompanyBeneficiaryFormType>
                id="notificationEmail"
                register={register}
                error={formState.errors.notificationEmail?.message}
                className="formInput"
                type="string"
                maxLength={32}
                label="Beneficiary email (CBZ will send proof of payment)"
                disabled={watchedCompanyVariant !== 'Manually' || type === 'recipient'}
              />
            </>
          )}
        </div>
        <div className="error-message">
          <Icon.WarningExclamationMark />
          <p>
            Please ensure that account details entered are correct as error will lead to delayed /
            failed transactions
          </p>
        </div>
      </form>
      <div className="btn-wrapper mb-10">
        <button type="button" className="btn btn-cancel" onClick={onCancel}>
          Cancel
        </button>

        {type === 'beneficiary' && (
          <button
            form="beneficiary-details-form"
            type="submit"
            className="btn btn-next"
            disabled={isDisabledButton || isLoadingDisabledBtn}
          >
            {textConfirmButton}
          </button>
        )}
      </div>
    </>
  )
}

export default CompanyBeneficiaryForm
