import { AmlStatus } from "./user";

export enum Gender {
  Male,
  Female,
}

export enum DocumentType {
  NationalId = 10,
  DriversLicense = 20,
  Passport = 30,
}

export enum BeneficiaryType {
  Individual,
  Company,
  MobileWalletBeneficiary
}

export enum SanctionStatus {
  NotOnSanctionsList,
  OnSanctionsListUnconfirmed,
  OnSanctionsListManualConfirmed,
  OnSanctionsListManualResolved
}

export type DocumentTypeOptions = { value: DocumentType; label: string }

export type KWLRecipient = {
  id?: number
  isDeleted?: boolean
  countryName?: string
  firstName: string
  lastName: string
  type: BeneficiaryType
  phoneNumber: string
  dateOfBirth?: string
  documentType?: DocumentType | string
  documentNumber?: string
  countryId?: number | string
  countryIso?: string
  documentCountryIso?: string
  city?: string
  street?: string
  postcode?: string
  gender?: Gender | string
  status?: string
  sanctionStatus?: SanctionStatus
  amlInspectionStatus?: AmlStatus

  //FCA
  bankId?: number | string | null
  accountNumber?: number | string
  reference?: number | string
  notificationEmail?: string
}

export type CompanyRecipient = {
  id?: number
  type: BeneficiaryType
  companyName: string
  countryId?: number
  amlInspectionStatus?: AmlStatus

  //FCA
  bankId?: number | string | null
  accountNumber?: number | string
  reference?: number | string
  notificationEmail?: string
  sanctionStatus?: SanctionStatus
}

export type CreateBeneficiaryByDefaultCompanyRequestBody = {
  reference: string | number
}

export type UpdateCompanyBeneficiaryRequestBody = CreateBeneficiaryByDefaultCompanyRequestBody

export type IndividualBeneficiaryFormType = {
  firstName: string
  lastName: string
  phoneNumber: string
  type: BeneficiaryType
  dateOfBirth?: string
  documentType?: DocumentType | string
  documentNumber?: string
  documentCountryIso?: string
  gender?: Gender | string
  countryId?: number | string
  city?: string
  street?: string
  postcode?: string

  //FCA
  bankId?: number | string | null
  accountNumber?: number | string
  reference?: number | string
  notificationEmail?: string
}



export type CompanyBeneficiaryFormType = {
  companyVariant?: string
  companyName: string
  countryId: number

  //FCA
  bankId?: number | string | null
  accountNumber?: number | string
  reference?: number | string
  notificationEmail?: string
}

export type BeneficiariesList = {
  items: Array<KWLRecipient>
  hasNextPage: boolean
}

export type CountryOption = {
  label: string
  value: string
  iso2Code: string
  countryId: number
  phoneCode: string
}

export type BeneficiaryLookUpForm = {
  nationalId: string
  type: BeneficiaryType | string
}

export type BeneficiaryLookUpServerResponse = {
  firstName: string
  lastName: string
  gender: Gender
  birthDate: string
}

export type GetBeneficiaryFieldsConfigResponse = {
  Individual: Record<string, { visible: boolean }>,
  Company: Record<string, { visible: boolean }>
}

export type ProviderNameForWithdrawal = {
  id: number
  displayName: string
  // order: number
}

export type ValidateWithdrawalAccountNumberRequestData = {
  accountNumber: number | string
  bankId: string | number
}

export type ValidateWithdrawalAccountNumberResponse = {
  holderName: string
  currency: string
  isValid: boolean
}

export type MobileWalletRecipient = {
  phoneNumber: string,
  type: BeneficiaryType
}

export type Company = {
  id?: number,
  countryName?: string,
  companyName: string,
  bankId: string,
  accountNumber: string,
  notificationEmail: string
}

export type GetCompanyListForBeneficiaryResponse = Array<Company>
